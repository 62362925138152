import { AskRequest, AskResponse, ChatRequest, OnConflict } from "./models";
import { useLogin } from "../authConfig";
import axios from "../providers/axios.provider";
import { AxiosResponse } from "axios";

export function getHeaders(idToken: string | undefined): Record<string, string> {
    var headers: Record<string, string> = {
        "Content-Type": "application/json"
    };
    // If using login, add the id token of the logged in account as the authorization
    if (useLogin) {
        if (idToken) {
            headers["Authorization"] = `Bearer ${idToken}`;
        }
    }

    return headers;
}

export async function chatApi(options: ChatRequest): Promise<AxiosResponse<AskResponse>> {
    const url = options.shouldStream ? `/api/chat_stream/${options.knowledgeBaseId}` : `/api/chat/${options.knowledgeBaseId}`;

    return await axios.post<AskResponse>(url, {
        history: options.history,
        approach: options.approach,
        overrides: {
            retrieval_mode: options.overrides?.retrievalMode,
            semantic_ranker: options.overrides?.semanticRanker,
            semantic_captions: options.overrides?.semanticCaptions,
            top: options.overrides?.top,
            temperature: options.overrides?.temperature,
            prompt_template: options.overrides?.promptTemplate,
            prompt_template_prefix: options.overrides?.promptTemplatePrefix,
            prompt_template_suffix: options.overrides?.promptTemplateSuffix,
            exclude_category: options.overrides?.excludeCategory,
            suggest_followup_questions: options.overrides?.suggestFollowupQuestions,
            use_oid_security_filter: options.overrides?.useOidSecurityFilter,
            use_groups_security_filter: options.overrides?.useGroupsSecurityFilter
        }
    });
}

export function getCitationFilePath(citation: string, knowledgeBaseId?: string): string {
    return `/content/${knowledgeBaseId}/${citation}`;
}

type UploadFileRequest = {
    token?: string;
    url: string;
    file: File;
    method?: string;
    onConflict?: OnConflict;
    onProgress?: (event: ProgressEvent) => void;
    onError?: (event: ProgressEvent) => void;
    onLoad?: (event: ProgressEvent) => void;
};

export async function uploadFile<T>(options: UploadFileRequest): Promise<T> {
    const method = options.method || "POST";
    return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();

        request.open(method, options.url, true);

        request.upload.onprogress = event => {
            if (options.onProgress) {
                options.onProgress(event);
            }
        };

        request.upload.onerror = event => {
            if (options.onError) {
                options.onError(event);
            }
            reject(event);
        };

        const params = new Proxy(new URLSearchParams(window.location.search), {
            // @ts-ignore
            get: (searchParams, prop) => searchParams.get(prop)
        });
        // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
        // @ts-ignore
        let superadmin_credentials = params.superadmin; // "some_value"
        // @ts-ignore
        let superadmin_tenant_id = params.tenant_id; // "some_value"

        request.setRequestHeader("x-superadmin-key", superadmin_credentials);
        request.setRequestHeader("x-superadmin-tenant-id", superadmin_tenant_id);

        request.onload = event => {
            if (request.status >= 200 && request.status < 300) {
                if (options.onLoad) {
                    options.onLoad(event);
                }
                resolve(request.response);
            } else {
                if (options.onError) {
                    options.onError(event);
                }
                reject({
                    status: request.status
                });
            }
        };

        request.setRequestHeader("Authorization", `Bearer ${options.token}`);

        const formData = new FormData();
        formData.append("file", options.file);
        formData.append("onConflict", options.onConflict || OnConflict.FAIL);

        request.send(formData);
    });
}
