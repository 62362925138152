import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ControlledTextField from '../../features/controller-text-field/controlled-text-field';
import { Button, Spinner, makeStyles } from '@fluentui/react-components';
import { createOrganizationRequest } from '../../api/organization';
import { useNavigate } from 'react-router-dom';
import { isAxiosError } from 'axios';
import { useEffect, useState } from 'react';

type OrganizationFormValues = {
    name: string;
    description?: string;
    homeUrl?: string;
};

const schema = yup.object().shape({
    name: yup.string().required('Organization name is required').max(50, 'Organization name must be less than 50 characters'),
    description: yup.string().max(200, 'Description must be less than 200 characters'),
    homeUrl: yup.string().url('Invalid URL'),
});

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '16px',
    },
    actions: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        columnGap: '20px',
    },
    buttonNonInteractive: {
        cursor: "default",
        pointerEvents: "none",
    },
})

type CreateOrganizationFormProps = {
    isLoading?: boolean;
}

export const CreateOrganizationForm = ({
    isLoading
}: CreateOrganizationFormProps) => {
    const navigate = useNavigate();

    const methods = useForm<OrganizationFormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
            description: '',
            homeUrl: '',
        },
    });

    const { setFocus, handleSubmit, formState: { errors } } = methods;

    const classes = useStyles();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [submitButtonState, setSubmitButtonState] = useState<"loading" | 'loaded' | 'initial'>('initial');

    const onSubmit = async (data: OrganizationFormValues) => {
        try {
            setIsSubmitting(true);
            setSubmitButtonState('loading');

            const organization = await createOrganizationRequest({
                name: data.name,
                description: data.description,
                homeUrl: data.homeUrl
            });

            navigate(`/`)
        } catch (error: unknown) {
            if (isAxiosError(error) && error.response?.data?.message) {
                console.error(error.response.data.message);
            } else {
                console.error(error);
            }
        } finally {
            setIsSubmitting(false);
            setSubmitButtonState('loaded');
            setTimeout(() => {
                setSubmitButtonState('initial');
            }, 1000)
        }
    };

    const buttonContent =
        submitButtonState === "loading"
            ? "Saving"
            : submitButtonState === "loaded"
                ? "Saved"
                : "Save";

    const buttonClassName =
        submitButtonState === "initial" ? undefined : classes.buttonNonInteractive;


    useEffect(() => {
        // @ts-ignore
        const firstError = Object.keys(errors).reduce((field, a) => {
            // @ts-ignore
            return !!errors[field] ? field : a;
        }, null);

        if (firstError) {
            // @ts-ignore
            setFocus(firstError);
        }
    }, [errors, setFocus]);


    return (
        <FormProvider {...methods}>
            <div className={classes.root}>
                <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>

                    <p><b>NOTE: </b>Fields marked with <span style={{
                        color: 'rgb(164, 38, 44)',
                    }}>*</span> are required.</p>

                    <ControlledTextField name="name" label="Company Name" disabled={isLoading} required={true} />

                    <ControlledTextField name="homeUrl" label="Home URL" disabled={isLoading} />

                    <ControlledTextField name="description" label="Description" multiline autoAdjustHeight disabled={isLoading} />

                    <div className={classes.actions}>
                        <Button
                            type="submit"
                            appearance="primary"
                            disabled={isLoading}
                            className={buttonClassName}
                            disabledFocusable={isSubmitting}
                            icon={isSubmitting ? <Spinner size="tiny" /> : null}
                        >
                            {buttonContent}
                        </Button>
                    </div>
                </form>
            </div>
        </FormProvider>
    );
};