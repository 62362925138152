import { MenuItem, MenuList, makeStyles, tokens, shorthands, mergeClasses, Menu, MenuTrigger, Button, MenuPopover, MenuItemLink, Title3, Body2, Subtitle1, Subtitle2 } from "@fluentui/react-components";
import { useCallback, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const useInlineStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        // backgroundColor: '#fff',
        ...shorthands.overflow("hidden"),
    },
    header: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        ...shorthands.padding("16px"),
        ...shorthands.borderBottom("1px", "solid", tokens.colorNeutralStroke1),
        ...shorthands.gap("8px"),
    },
    container: {
        display: "flex",
        flexDirection: "row",
        height: "100%",
        width: "100%",
    },
    navigation: {
        display: 'none'
    },
    activeMenuItem: {
        backgroundColor: tokens.colorNeutralBackground1Selected,
    },
    main: {
        display: "flex",
        flexDirection: "row",
        height: "100%",
        width: "100%",
    }
});

const useOverlayStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        // backgroundColor: '#fff',
        ...shorthands.overflow("hidden"),
    },
    header: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        ...shorthands.padding("16px"),
        ...shorthands.gap("8px"),
    },
    container: {
        display: "flex",
        flexDirection: "row",
        height: "100%",
        width: "100%",
    },
    navigation: {
        backgroundColor: tokens.colorNeutralBackground1,
        minWidth: "265px",
        minHeight: "48px",
        maxWidth: "375px",
        width: "max-content",
        boxShadow: `${tokens.shadow16}`,
        paddingTop: "4px",
        paddingBottom: "4px",
    },
    activeMenuItem: {
        backgroundColor: tokens.colorNeutralBackground1Selected,
    },
    main: {
        display: "flex",
        flexDirection: "row",
        flexGrow: "1",
        height: "100%",
        width: "100%",
    }
})

const applicationSettings = [
    {
        label: "Appearance",
        path: "/settings/appearance",
    }
];

export const ApplicationSettingsLayout = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const overlayStyles = useInlineStyles();
    const inlineStyles = useOverlayStyles()

    const [type, setType] = useState("inline");

    const styles = type === "overlay" ? overlayStyles : inlineStyles;

    const onMediaQueryChange = useCallback(
        ({ matches }: MediaQueryListEvent) => setType(matches ? "overlay" : "inline"),
        [setType]
    );

    useEffect(() => {
        const match = window.matchMedia("(max-width: 720px)");

        if (match.matches) {
            setType("overlay");
        }

        match.addEventListener("change", onMediaQueryChange);

        return () => match.removeEventListener("change", onMediaQueryChange);
    }, [onMediaQueryChange]);

    const navigateToSettings = (path: string) => {
        navigate(path);
    }

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <Title3>Application settings</Title3>
                {type === "overlay" ? (
                    <Menu>
                        <MenuTrigger disableButtonEnhancement>
                            <Button size="small" shape='square'>{applicationSettings.find(item => item.path === location.pathname)?.label}</Button>
                        </MenuTrigger>

                        <MenuPopover>
                            <MenuList>
                                {applicationSettings.map((item, index) => (
                                    <MenuItem key={index} className={mergeClasses(item.path === location.pathname ? styles.activeMenuItem : "")} onClick={() => navigateToSettings(item.path)}>{item.label}</MenuItem>
                                ))}
                            </MenuList>
                        </MenuPopover>
                    </Menu>
                ) : (
                    <Subtitle2>| {applicationSettings.find(item => item.path === location.pathname)?.label}</Subtitle2>
                )}
            </div>
            <div className={styles.container}>
                <div className={styles.navigation}>
                    {type === 'inline' ? (
                        <MenuList>
                            {applicationSettings.map((item, index) => (
                                <MenuItem key={index} className={mergeClasses(item.path === location.pathname ? styles.activeMenuItem : "")} onClick={() => navigateToSettings(item.path)}>{item.label}</MenuItem>
                            ))}
                        </MenuList>
                    ) : null}
                </div>

                <div className={styles.main}>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}