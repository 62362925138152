import React, { useEffect } from "react";
import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    Button,
    makeStyles,
    shorthands,
    DrawerProps as InlineDrawerProps,
    Drawer,
    Subtitle2,
    drawerHeaderClassNames,
    drawerHeaderTitleClassNames,
    Tooltip
} from "@fluentui/react-components";
import { ChevronDoubleLeft20Filled } from "@fluentui/react-icons";
import { KnowledgeBases } from "../knowledge-bases/knowledge-bases";
import UserMenu from "../user-menu";
import { NavigationItems } from "./navigation-menu";
import { useParams, Link } from "react-router-dom";
import { useAppAbility } from "../../providers/ability.provider";
import oilogo from "../../assets/OILogo.png";
import { useDrawer } from "../../providers/drawer.provider";

const useStyles = makeStyles({
    drawer: {
        backgroundImage: "none",
        flexShrink: 0,
    },
    [`& > .${drawerHeaderClassNames.root}`]: {
        [`& > .${drawerHeaderTitleClassNames.heading}`]: {
            flexGrow: 1
        }
    },
    drawerHeaderTitle: {
        width: "100%"
    },
    drawerHeader: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: "100%",
        paddingTop: '10px'
    },
    navigation: {
        ...shorthands.overflow("auto"),
        height: "100%",
        paddingRight: '2px'
    },
    headerContainer: {
        width: "100%",
        display: "flex",
    },
    headerTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        marginRight: '2%',
        marginLeft: '2%',
        width: "90%",
        // color: 'var(--warm-white)',
        textDecoration: 'none'
    },
    headerTitle: {
        fontSize: '90%',
        fontWeight: '600',
        fontStyle: 'none',
        color: 'black',
        lineHeight: '1',
        width: "100%"
    },
    headerSubTitle: {
        fontSize: '80%',
        fontWeight: '400',
        fontStyle: 'none',
        color: 'black',
        lineHeight: '1',
        width: "100%"
    },
    version: {
        fontWeight: "400",
        fontSize: "small",
    },
    outlookInsightLogo: {
        height: '54px',
        width: '54px',
        marginTop: '6px'
    },
});

const useListStyles = makeStyles({
    fullHeight: {
        height: "100%"
    },
    list: {
        height: "100%",
        ...shorthands.padding(0),
        ...shorthands.margin(0),
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap('16px'),
        listStyleType: 'none'
    },
    listTitle: {
        marginBottom: '2px',
        display: 'block',
        borderBottom: '2px solid #d9d9d9',
        paddingBottom: '4px',
    },
    flexGrow: {
        flexGrow: 1
    },
    overflowHidden: {
        ...shorthands.overflow('hidden'),
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
})

type DrawerType = Required<InlineDrawerProps>["type"];

type DrawerProps = {
    position: InlineDrawerProps["position"];
};

export const ResponsiveDrawer: React.FC<DrawerProps> = ({
    position,
}) => {
    const styles = useStyles();
    const listStyles = useListStyles();
    const params = useParams<{
        knowledgeBaseId: string;
    }>()
    const ability = useAppAbility();
    const drawer = useDrawer();

    const [type, setType] = React.useState<DrawerType>("inline");
    const [size, setSize] = React.useState<InlineDrawerProps["size"]>("small");
    const [isSmallScreen, setIsSmallScreen] = React.useState(false);

    const onMediaQueryChange = React.useCallback(
        ({ matches }: MediaQueryListEvent) => {
            setType(matches ? "overlay" : "inline")
            drawer.setDrawerState(matches)
            setIsSmallScreen(matches);
            //drawer.isOverlay = matches;
        },
        [setType]
    );

    useEffect(() => {
        const match = window.matchMedia("(max-width: 560px)");
        const matchMobile = window.matchMedia("(max-width: 450px)");

        if (matchMobile.matches) {
            setSize("full");
        } else {
            setSize("small");
        }

        //       if (match.matches) {
        //     setType("overlay");
        //     drawer.setDrawerState(false)
        // }

        setType(match.matches ? "overlay" : "inline");
        drawer.setDrawerState(!match.matches);
        setIsSmallScreen(match.matches);

        match.addEventListener("change", onMediaQueryChange);

        return () => match.removeEventListener("change", onMediaQueryChange);
    }, [onMediaQueryChange]);

    const drawerToggleRef = React.useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (drawer.isDrawerOpen) {
            drawerToggleRef.current?.focus();
        }
    }, [drawer.isDrawerOpen])

    return (
        <Drawer
            type={type}
            size={size}
            separator
            position={position}
            open={drawer.isDrawerOpen}
            onOpenChange={(_, { open }) => drawer.setDrawerState(open)}
            className={styles.drawer}

        >
            <DrawerHeader className={styles.drawerHeader}>
                <DrawerHeaderTitle
                    as='div'
                    style={{
                        flexGrow: 1
                    }}
                    className={styles.drawerHeaderTitle}
                    action={
                        drawer.isDrawerOpen && type === "overlay" && isSmallScreen ? (
                            <Tooltip content="Close sidebar menu" relationship="label">
                                <Button
                                    appearance="subtle"
                                    aria-expanded={drawer.isDrawerOpen}
                                    icon={<ChevronDoubleLeft20Filled />}
                                    onClick={() => drawer.setDrawerState(false)}
                                    ref={drawerToggleRef}
                                />
                            </Tooltip>
                        ) : null
                    }
                    heading={{
                        as: 'div',
                    }}
                >
                    <div className={styles.headerContainer}>
                    <Link to="https://www.outlookinsight.com" aria-label="Outlook Insight's website">
                        <img src={oilogo} className={styles.outlookInsightLogo} alt="Outlook Insight company logo" />
                    </Link>
                        <span className={styles.headerTitleContainer}>
                            <span className={styles.headerSubTitle}>Outlook Insight's</span>
                            <span className={styles.headerTitle}>Knowledge Interpreter</span>
                        </span>
                        <span className={styles.version}>v2.1.13</span>
                    </div>
                </DrawerHeaderTitle>
            </DrawerHeader>

            <DrawerBody className={styles.drawer}>
                <nav className={styles.navigation}>
                    <ul className={listStyles.list}>
                        <li>
                            <Subtitle2 className={listStyles.listTitle}>User</Subtitle2>
                            <UserMenu />
                        </li>
                        <li className={`${listStyles.flexGrow} ${listStyles.overflowHidden} ${listStyles.flexColumn}`} style={{
                            overflow: 'hidden',
                            minWidth: '200px',
                            flexShrink: '0',
                            flexGrow: '1',
                            minHeight: '300px'
                        }}>
                            <Subtitle2 className={listStyles.listTitle}>Knowledge Bases</Subtitle2>
                            <div className={`${listStyles.overflowHidden} ${listStyles.flexGrow}`}>
                                <KnowledgeBases selectedKnowledgeBaseId={params?.knowledgeBaseId} />
                            </div>
                        </li>
                        {ability.can('manage', 'administration') ? (
                            <li>
                                <Subtitle2 className={listStyles.listTitle}>Administration</Subtitle2>
                                <NavigationItems />
                            </li>
                        ) : null}
                    </ul>
                </nav>
            </DrawerBody>
        </Drawer >
    );
};
