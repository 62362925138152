import { useMsal } from "@azure/msal-react";
import { AppAbility, createAbility, updateAbilityForIdTokenClaims } from "../rbac/define-ability";
import { createContextualCan, useAbility } from "@casl/react";
import { createContext, useEffect } from "react";


export const AbilityContext = createContext<AppAbility>(
    null as unknown as AppAbility,
);

// https://graph.microsoft.com/v1.0/servicePrincipals/d9137ada-e527-4d3e-ba43-86e455cbfa47/appRoleAssignedTo

export const Can = createContextualCan(AbilityContext.Consumer);

export const useAppAbility = () => useAbility(AbilityContext);

export const ability = createAbility();

type AbilityProviderProps = { children: React.ReactNode };

export const AbilityProvider = (props: AbilityProviderProps) => {

    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();

    useEffect(() => {
        updateAbilityForIdTokenClaims(ability, activeAccount?.idTokenClaims);
    }, [activeAccount?.idTokenClaims]);

    return (
        <AbilityContext.Provider value={ability}>
            {props.children}
        </AbilityContext.Provider>
    );
};
