import React, { useEffect, useState } from "react";
import { getOrganizationRequest } from "../api/organization";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { AxiosError } from "axios";
import { Organization } from "../api";
import { useLocation, useNavigate } from "react-router-dom";

const OrganizationContext = React.createContext({} as {
    organization?: Organization | null,
    isOrganizationLoading: boolean,
    getOrganization: () => void;
});

export const useOrganization = () => {
    const context = React.useContext(OrganizationContext);

    if (!context) {
        throw new Error("useOrganization must be used within a OrganizationProvider");
    }

    return context;
}

type OrganizationProviderProps = {
    children: React.ReactNode;
}

export const OrganizationProvider = (props: OrganizationProviderProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount()

    const [organization, setOrganization] = useState<Organization | null>(null);
    const [isOrganizationLoading, setIsOrganizationLoading] = useState(false);

    const getOrganization = async () => {
        try {
            setIsOrganizationLoading(true);

            if (isAuthenticated) {
                const response = await getOrganizationRequest()

                const organization = response.data

                setOrganization(organization);
            } else {
                setOrganization(null);
            }
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                if (error.response?.status === 401) {
                    // const client = useMsal().instance;
                    // client.loginRedirect();
                    console.error("Unauthorized to get organization");
                } else if (error.response?.status === 403) {
                    console.error("Forbidden to get organization");
                } else if (error.response?.status === 404) {
                    console.error("Organization not found");
                    navigate("/onboarding")
                }
            }
            console.error(error);
        } finally {
            setIsOrganizationLoading(false)
        }
    }

    useEffect(() => {
        getOrganization();
    }, [activeAccount?.tenantId, isAuthenticated])

    return (
        <OrganizationContext.Provider value={{ organization, isOrganizationLoading, getOrganization }}>
            {props.children}
        </OrganizationContext.Provider>
    )
}