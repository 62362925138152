// DrawerContext.js
import React, { createContext, useContext, useState } from 'react';

interface DrawerContextType {
    isDrawerOpen: boolean;
    //isOverlay: boolean;
    //setOverlayState: (isOverlay: boolean) => void;
    openDrawer: () => void;
    closeDrawer: () => void;
    toggleDrawer: () => void;
    setDrawerState: (state: boolean) => void;
}

// Create a context
const DrawerContext = createContext<DrawerContextType>({
    isDrawerOpen: false,
    //isOverlay: false,
    //setOverlayState: () => { },
    openDrawer: () => { },
    closeDrawer: () => { },
    toggleDrawer: () => { },
    setDrawerState: () => { },
});

type DrawerProviderProps = {
    children: React.ReactNode;
}

// Create a provider component
export const DrawerProvider = ({ children }: DrawerProviderProps) => {
    const [isDrawerOpen, setDrawerOpen] = useState(true);
    //const [isOverlay, setOverlayState] = useState(true);

    const openDrawer = () => setDrawerOpen(true);
    const closeDrawer = () => setDrawerOpen(false);
    const toggleDrawer = () => setDrawerOpen((prev) => !prev);
    const setDrawerState = (state: boolean) => setDrawerOpen(state);

    return (
        <DrawerContext.Provider value={{ isDrawerOpen, openDrawer, closeDrawer, toggleDrawer, setDrawerState }}>
            {children}
        </DrawerContext.Provider>
    );
};

// Custom hooks for consuming the context
export const useDrawer = () => {
    const context = useContext(DrawerContext);
    if (context === undefined) {
        throw new Error('useDrawer must be used within a DrawerProvider');
    }
    return context;
};
