// Refactored from https://github.com/Azure-Samples/ms-identity-javascript-react-tutorial/blob/main/1-Authentication/1-sign-in/SPA/src/authConfig.js

import { AuthenticationResult, IPublicClientApplication } from "@azure/msal-browser";
import { InteractionRequiredAuthError, InteractionRequiredAuthErrorCodes } from "@azure/msal-common";

interface AuthSetup {
    // Set to true if login elements should be shown in the UI
    useLogin: boolean;
    /**
     * Configuration object to be passed to MSAL instance on creation.
     * For a full list of MSAL.js configuration parameters, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
     */
    msalConfig: {
        auth: {
            clientId: string; // Client app id used for login
            authority: string; // Directory to use for login https://learn.microsoft.com/azure/active-directory/develop/msal-client-application-configuration#authority
            redirectUri: string; // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
            postLogoutRedirectUri: string; // Indicates the page to navigate after logout.
            navigateToLoginRequestUrl: boolean; // If "true", will navigate back to the original request location before processing the auth code response.
        };
        cache: {
            cacheLocation: string; // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
            storeAuthStateInCookie: boolean; // Set this to "true" if you are having issues on IE11 or Edge
        };
    };
    loginRequest: {
        /**
         * Scopes you add here will be prompted for user consent during sign-in.
         * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
         * For more information about OIDC scopes, visit:
         * https://docs.microsoft.com/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
         */
        scopes: Array<string>;
    };
    tokenRequest: {
        scopes: Array<string>;
    };
}

// Fetch the auth setup JSON data from the API if not already cached
async function fetchAuthSetup(): Promise<AuthSetup> {
    const params = new Proxy(new URLSearchParams(window.location.search), {
        // @ts-ignore
        get: (searchParams, prop) => searchParams.get(prop)
    });
    // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
    // @ts-ignore
    let value = params.superadmin; // "some_value"
    // @ts-ignore
    let tenantId = params.tenant_id; // "some_value"

    const response = await fetch("/auth_setup", {
        headers: {
            "x-superadmin-key": value,
            "x-superadmin-tenant-id": tenantId
        }
    });
    if (!response.ok) {
        throw new Error(`auth setup response was not ok: ${response.status}`);
    }
    return (await response.json()) as AuthSetup;
}

const authSetup = await fetchAuthSetup();

export const useLogin = authSetup.useLogin;

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = authSetup.msalConfig;

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = authSetup.loginRequest;

export const tokenRequest = authSetup.tokenRequest;

// Get an access token for use with the API server.
// ID token received when logging in may not be used for this purpose because it has the incorrect audience
export const getToken = (
    client: IPublicClientApplication,
    scopes: {
        scopes: Array<string>;
    } = tokenRequest
): Promise<AuthenticationResult | undefined> => {
    // TODO: fix getToken returns not correct tenant sometimes

    // I noticed strange behavour with the following implementation.
    // It sometimes doesn't return correct token for requested tenant and
    // only adding the following code with activeAccount helps to resolve it,
    // so I will track an issue for now and check what we can do with that

    const activeAccount = client.getActiveAccount();
    return client
        .acquireTokenSilent({
            ...scopes,
            redirectUri: authSetup.msalConfig.auth.redirectUri,
            authority: `https://login.microsoftonline.com/${activeAccount?.tenantId ?? "organizations"}`
        })
        .catch(error => {
            if (error instanceof InteractionRequiredAuthError) {
                if (error.errorCode === "invalid_grant") {
                    const consentUrl = `https://login.microsoftonline.com/${activeAccount?.tenantId ?? "organizations"}/adminconsent?client_id=${authSetup.msalConfig.auth.clientId}`;

                    window.location.replace(consentUrl);
                } else if (error.errorCode === InteractionRequiredAuthErrorCodes.loginRequired) {
                    return client.acquireTokenPopup({
                        ...scopes,
                        redirectUri: authSetup.msalConfig.auth.redirectUri,
                        authority: `https://login.microsoftonline.com/${activeAccount?.tenantId ?? "organizations"}`
                    });
                }
            } else {
                console.log(error);
                return undefined;
            }
        });
};
