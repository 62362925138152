import { Directory } from ".";
import axios from "../providers/axios.provider";
import { AxiosResponse } from "axios";

type AzureAdDirectoryResponse = {
    value?: Directory[];
};

export const getDirectoriesRequest = async (): Promise<AxiosResponse<AzureAdDirectoryResponse>> => {
    return await axios.get<AzureAdDirectoryResponse>("https://management.azure.com/tenants?api-version=2022-12-01");
};

export type AzureAdOrganization = {
    id: string;
    displayName: string;
};

type AzureAdOrganizationResponse = {
    value?: AzureAdOrganization[];
};

export const requestAzureADOrganization = async (): Promise<AxiosResponse<AzureAdOrganizationResponse>> => {
    return await axios.get<AzureAdOrganizationResponse>("https://graph.microsoft.com/v1.0/organization");
};

export const getPhotoRequest = async () => {
    return await axios.get("https://graph.microsoft.com/v1.0/me/photo/$value");
};
