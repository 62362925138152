import { makeStyles, shorthands } from "@fluentui/react-components"
import { Outlet } from "react-router-dom"


const useStyles = makeStyles({
    root: {
        ...shorthands.padding('20px'),
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto'
    }
})

export const PaddingLayout = () => {
    const styles = useStyles();
    return <div className={styles.root}>
        <Outlet />
    </div>
}