import { useMsal } from "@azure/msal-react";
import { Navigate } from "react-router-dom";

type UnauthorizedRouteProps = {
    children: React.ReactNode;
}

export const UnauthorizedRoute = ({ children }: UnauthorizedRouteProps) => {
    const { instance, inProgress } = useMsal();
    const activeAccount = instance.getActiveAccount();

    if (activeAccount) return <Navigate to="/" replace />;

    if (inProgress === 'startup') return null;

    return children;

    // <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
    //     {children}
    // </MsalAuthenticationTemplate>
}