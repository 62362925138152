import { Outlet, createHashRouter } from "react-router-dom";

import "./index.scss";

import DashboardLayout from "./layouts/dashboard-layout";
import ProtectedRoute from "./providers/protected-route";
import ApplicationSettingsLayout from "./layouts/application-settings-layout";
import { PaddingLayout } from "./layouts/padding-layout";
import { OrganizationProvider } from "./providers/organization.provider";
import CreateOrganizationPage from "./pages/onboarding/page";
import UnauthorizedLayout from "./layouts/unauthorized-layout";
import { UnauthorizedRoute } from "./providers/unauthorized-route";
import { AbilityProvider } from "./providers/ability.provider";
import { AdministrationGuard } from "./features/ability-guard/administration-guard";
import UserProvider from "./providers/user.provider";


export const router = createHashRouter([
    {
        path: 'auth',
        element: <UnauthorizedRoute>
            <UnauthorizedLayout />
        </UnauthorizedRoute>,
        children: [
            {
                path: "sign-in",
                lazy: () => import("./pages/sign-in/page")
            },
            {
                path: "*",
                lazy: () => import("./pages/not-found/NoPage")
            }
        ]
    },
    {
        path: 'onboarding',
        // TODO: too much duplicates, consider refactoring
        element: <ProtectedRoute>
            <OrganizationProvider>
                <UserProvider>
                    <AbilityProvider>
                        <CreateOrganizationPage />
                    </AbilityProvider>
                </UserProvider>
            </OrganizationProvider>
        </ProtectedRoute>,
    },
    {
        path: '',
        element:
            <ProtectedRoute>
                <OrganizationProvider>
                    <UserProvider>
                        <AbilityProvider>
                            <DashboardLayout />
                        </AbilityProvider>
                    </UserProvider>
                </OrganizationProvider>
            </ProtectedRoute>,
        children: [
            {
                index: true,
                lazy: () => import('./pages/home/page')
            },
            {
                path: 'settings',
                element: <ApplicationSettingsLayout />,

                children: [
                    {
                        path: 'appearance',
                        lazy: () => import('./pages/settings/appearance/page'),
                    },
                ]
            },
            {
                path: 'chat',
                element: <Outlet />,
                children: [
                    {
                        index: true,
                        lazy: () => import('./pages/home/page')
                    },
                    {
                        path: ':knowledgeBaseId',
                        lazy: () => import('./pages/chat/page')
                    }
                ]
            },
            {
                path: 'administration',
                element: <AdministrationGuard><PaddingLayout /></AdministrationGuard>,
                children: [
                    {
                        // index: true,
                        path: 'organization',
                        lazy: () => import('./pages/administration/organization/page')
                    },
                    {
                        path: 'knowledge-base',
                        children: [
                            {
                                index: true,
                                lazy: () => import('./pages/administration/knowledge-bases/page')
                            },
                            {
                                path: 'create',
                                lazy: () => import('./pages/administration/knowledge-base-create/knowledge-base-creator')
                            },
                            {
                                path: ':knowledgeBaseId',
                                lazy: () => import('./pages/administration/knowledge-base/page')
                            },
                        ]
                    },
                    // {
                    //     path: 'user-management',
                    //     lazy: () => import('./pages/administration/user-management/page')
                    // }
                ]
            },
            {
                path: "*",
                lazy: () => import("./pages/not-found/NoPage")
            }
        ]
    },
    {
        path: 'content',
    }
]);