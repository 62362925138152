import { Field, Input, Text, Textarea, makeStyles, shorthands, InfoLabel, LabelProps } from "@fluentui/react-components";
import { ErrorCircleFilled } from "@fluentui/react-icons";
import { Controller } from "react-hook-form";
import React from "react";

const useStyles = makeStyles({
    inputError: {
        color: 'red',
        ...shorthands.borderColor("#a4262c"),
    },
    hintContainer: {
        display: "flex",
        alignItems: "center",
        gap: "4px",
    },
    hintError: {
        color: "#a4262c"
    },
    iconError: {
        color: "#a4262c",
        fontSize: "16px",
    },
    Input: {
        overflow: "scroll",
    },
})

type TextFieldProps = {
    name: string;
    label: string;
    multiline?: boolean;
    autoAdjustHeight?: boolean;
    required?: boolean;
    errorMessage?: string;
    info?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => void;
    onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    value?: string;
    disabled?: boolean;
    height?: string;
}

const TextField = React.forwardRef<any, TextFieldProps>((props, ref) => {
    const styles = useStyles();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (props.onChange) {
            props.onChange(event, event.target.value);
        }
    };

    let label;

    if (props.info || props.required) {
        label = {
            children: (_: unknown, labelInfoProps: LabelProps) => (
                <InfoLabel {...labelInfoProps} required={props.required} info={props.info}>
                    {props.label}
                </InfoLabel>
            ),
        }
    } else {
        label = props.label;
    }

    const inputStyle = props.height ? { height: props.height } : {};

    return (
        <Field
            // @ts-ignore
            label={label}
            hint={props.errorMessage ? (
                <div aria-live="polite" aria-atomic="true" className={styles.hintContainer}>
                    <ErrorCircleFilled className={styles.iconError} aria-label="Error" />
                    <Text className={styles.hintError}>
                        {props.errorMessage}
                    </Text>
                </div>
            ) : null}
        >
            {props.multiline ? (
                <Textarea
                    name={props.name}
                    value={props.value}
                    className={props.errorMessage ? styles.inputError : ''}
                    onChange={handleChange}
                    onBlur={props.onBlur}
                    disabled={props.disabled}
                    ref={ref}
                    style={inputStyle}
                />
            ) : (
                <Input
                    name={props.name}
                    value={props.value}
                    className={props.errorMessage ? styles.inputError : ''}
                    onChange={handleChange}
                    onBlur={props.onBlur}
                    disabled={props.disabled}
                    ref={ref}
                    input={{
                        type: "text",
                    }}
                    style={inputStyle}
                />
            )}
        </Field>
    );
});

type ControllerTextFieldProps = {
    name: string;
    label: string;
    multiline?: boolean;
    autoAdjustHeight?: boolean;
    required?: boolean;
    disabled?: boolean;
    info?: string;
    height?: string;
}

const ControlledTextField = (props: ControllerTextFieldProps) => {
    return (
        <Controller
            name={props.name}
            render={({ field, fieldState }) => (
                <TextField
                    {...field}
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    ref={field.ref}
                    multiline={props.multiline}
                    autoAdjustHeight={props.autoAdjustHeight}
                    disabled={props.disabled || field.disabled}
                    label={props.label}
                    required={props.required}
                    info={props.info}
                    errorMessage={fieldState.error?.message}
                    height={props.height}
                />
            )}
        />
    );
}

export default ControlledTextField;
