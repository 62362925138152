export function stringToBrightColor(str: string) {
    // Convert the string to a hash
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Convert the hash to a hexadecimal color
    let color = '';
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        // Ensure the color component is bright enough
        value = Math.min(255, value + 100);
        let stringValue = value.toString(16);
        color += ('00' + stringValue).slice(-2);
    }

    return color;
}
