import { useId, Toaster, useToastController, ToastId } from "@fluentui/react-components";
import React, { createContext, useContext } from "react";

type ToastContextType = {
    addToast: (message: string, type: string) => void;
    removeToast: (id: string) => void;
    // toasts: Array<ToastType>;
}

type ToastControllerType = ReturnType<typeof useToastController>;

const ToastContext = createContext<ToastControllerType>(
    {
        dispatchToast: () => {
            throw new Error("dispatchToast not implemented");
        },
        dismissToast: () => {
            throw new Error("dismissToast not implemented");
        },
        dismissAllToasts: () => {
            throw new Error("dismissAllToasts not implemented");
        },
        updateToast: () => {
            throw new Error("updateToast not implemented");
        },
        pauseToast: () => {
            throw new Error("pauseToast not implemented");
        },
        playToast: () => {
            throw new Error("playToast not implemented");
        }
    }
);

export const useToast = () => {
    const context = useContext(ToastContext);

    if (!context) {
        throw new Error("useToastController must be used within a ToastProvider");
    }

    return context;
}

type ToastProviderProps = {
    children: React.ReactNode;
}

const ToastProvider = ({
    children
}: ToastProviderProps) => {
    const toasterId = useId('toaster');

    const toastController = useToastController(toasterId);

    return (
        <ToastContext.Provider value={toastController}>
            <Toaster toasterId={toasterId} />
            {children}
        </ToastContext.Provider>
    )
}

export default ToastProvider