import { useMsal } from "@azure/msal-react";
import { Navigate } from "react-router-dom";

type ProtectedRouteProps = {
    children: React.ReactNode;
}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
    const { instance, inProgress } = useMsal();
    const activeAccount = instance.getActiveAccount();

    if (!activeAccount) return <Navigate to="/auth/sign-in" replace />;

    if (inProgress === 'startup') return null;

    return children;

    // <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
    //     {children}
    // </MsalAuthenticationTemplate>
}