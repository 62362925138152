import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication, EventType, AccountInfo, BrowserCacheLocation } from '@azure/msal-browser';
import { initializeIcons } from "@fluentui/react";
import { msalConfig } from "./authConfig";
import ThemeProvider from "./providers/theme.provider";
import { router } from "./routes";
import ToastProvider from "./providers/toast.provider";
import { AxiosInterceptor } from "./providers/axios.provider";

import "./index.scss";
import { AnnounceProvider, AriaLiveAnnouncer } from "@fluentui/react-components";


// TODO: consider adding react-query
// https://tanstack.com/query/latest/docs/framework/react/installation#requirements
// Chrome >= 91
// Firefox >= 90
// Edge >= 91
// Safari >= 15
// iOS >= 15
// Opera >= 77

// import {
//     QueryClient,
//     QueryClientProvider,
// } from '@tanstack/react-query'

// const queryClient = new QueryClient()

initializeIcons();

var msalInstance = new PublicClientApplication({
    ...msalConfig,
    cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage
    }
});

msalInstance.initialize().then(() => {
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    // Optional - This will update account state if a user signs in from another tab or window
    msalInstance.enableAccountStorageEvents();

    // Listen for sign-in event and set active account
    msalInstance.addEventCallback((event) => {
        if (
            (
                event.eventType === EventType.LOGIN_SUCCESS ||
                event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
                event.eventType === EventType.SSO_SILENT_SUCCESS
            ) && event.payload
        ) {
            const account = event.payload as AccountInfo;
            msalInstance.setActiveAccount(account);
        }
    });

    ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
        <ThemeProvider>
            <AriaLiveAnnouncer>
                {/* <QueryClientProvider client={queryClient}> */}
                <ToastProvider>
                    <MsalProvider instance={msalInstance}>
                        <AxiosInterceptor>

                            <RouterProvider router={router} />
                        </AxiosInterceptor>
                    </MsalProvider>
                </ToastProvider>
            </AriaLiveAnnouncer>
            {/* </QueryClientProvider> */}
        </ThemeProvider>
    );
})
