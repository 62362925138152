import React, { useEffect, useState, createContext, useContext } from "react";
import { createCurrentUserRequest, getCurrentUserRequest } from "../api/user";
import { useMsal } from "@azure/msal-react";
import { User } from "../api";
import { isAxiosError } from "axios";
import { Spinner } from "@fluentui/react-components";
import { updateAbility } from "../rbac/define-ability";
import { ability } from "./ability.provider";

const UserContext = createContext<{
    user: User | null;
    isUserLoading: boolean;
    isUserError: boolean;
    getUser: () => Promise<void>;
    setUser: (user: User | null) => void;
}>({
    user: null,
    isUserLoading: false,
    isUserError: false,
    getUser: () => Promise.resolve(),
    setUser: () => { }
});

export const useUser = () => {
    const context = useContext(UserContext);

    if (!context) {
        throw new Error("useUser must be used within a UserProvider");
    }

    return context;
};

type UserProviderProps = {
    children: React.ReactNode;
}

const UserProvider = ({ children }: UserProviderProps) => {
    const [user, setUser] = useState<User | null>(null);
    const [isUserLoading, setIsUserLoading] = useState(true);
    const [isUserError, setIsUserError] = useState(false);

    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();

    const getUser = async () => {
        try {
            setIsUserLoading(true);
            setIsUserError(false);

            let response;
            try {
                response = await getCurrentUserRequest();
            } catch (error) {
                // if (isAxiosError(error) && error.response?.status === 404) {
                //     response = await createCurrentUserRequest();
                // } else {
                //     throw error;
                // }
            } finally {
                response = await createCurrentUserRequest();
            }
            // updateAbility(ability, response.data);
            setUser(response.data);
        } catch (error) {
            setIsUserError(true);
        } finally {
            setIsUserLoading(false);
        }
    };

    useEffect(() => {
        if (activeAccount?.idTokenClaims?.oid) {
            getUser();
        }
    }, [activeAccount?.idTokenClaims?.oid]);

    return (
        <UserContext.Provider value={{ user, isUserLoading, isUserError, getUser, setUser }}>
            {children}
        </UserContext.Provider>
    );
}

export default UserProvider;
