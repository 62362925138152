import { FluentProvider, webDarkTheme, webLightTheme } from "@fluentui/react-components";
import { createContext, useContext, useEffect, useState } from "react";

export enum AppTheme {
    Light = "light",
    Dark = "dark",
}

const ThemeContext = createContext({} as {
    theme: AppTheme,
    setTheme: (theme: AppTheme) => void;
});

export const useTheme = () => {
    const context = useContext(ThemeContext);

    if (!context) {
        throw new Error("useTheme must be used within a ThemeProvider");
    }

    return context;
}

const ThemeProvider = (props: { children: React.ReactNode }) => {
    // Retrieve the theme from local storage or use default light theme
    const [theme, setTheme] = useState<AppTheme>(() => {
        const savedTheme = localStorage.getItem('theme');
        return (savedTheme === AppTheme.Dark) ? AppTheme.Dark : AppTheme.Light;
    });

    // Save the selected theme to local storage whenever it changes
    useEffect(() => {
        localStorage.setItem('theme', theme);
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            <FluentProvider theme={
                theme === AppTheme.Light ? webLightTheme : webDarkTheme
            }>
                {props.children}
            </FluentProvider>
        </ThemeContext.Provider>
    );
}

export default ThemeProvider;
