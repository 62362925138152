import { makeStyles } from "@fluentui/react-components";
import { Outlet } from "react-router-dom";

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        backgroundColor: '#323087',
    },
});

const UnauthorizedLayout = () => {
    const styles = useStyles()
    return (
        <div className={styles.root}>
            <div className="w-full max-w-md">
                <Outlet />
            </div>
        </div>
    )
}

export default UnauthorizedLayout;