import {
    Caption1,
    Card,
    CardHeader,
    shorthands,
    Text,
    makeStyles,
    cardHeaderClassNames,
    tokens,
    useId,
} from '@fluentui/react-components';
import { stringToBrightColor } from '../../helpers/string-to-bright-color';
import { KeyboardEventHandler } from 'react';
// import styles from './knowledge-base-selector.module.scss';



const useStyles = makeStyles({
    card: {
        width: '100%',
        height: "fit-content",

        [`& > .${cardHeaderClassNames.root}`]: {
            flexShrink: 1,
        },
    },

    active: {
        backgroundColor: '#dee5ff',
    },

    header: {
        flexShrink: 1,
        minWidth: '0px',

        [`& > .${cardHeaderClassNames.description}`]: {
            ...shorthands.overflow("hidden"),
            minWidth: '0px',
        },
        [`& > .${cardHeaderClassNames.header}`]: {
            flexShrink: 1,
        },
    },

    preview: {
        flexShrink: 0,
        maxWidth: "64px",
        maxHeight: "64px"
    },

    caption: {
        color: tokens.colorNeutralForeground3,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        ...shorthands.overflow("hidden"),
    },

    cardFooter: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        ...shorthands.gap("4px"),
    },
    cardFooterItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        ...shorthands.gap("4px"),
    }
});

type KnowledgeBaseCardSelectableProps = {
    id: string;
    title: string;
    subtitle: string;
    selected: boolean;
    onClick?: () => void;
    documentCount: number;
    updatedAt: string;
}

export const KnowledgeBaseCardSelectable = ({
    id,
    title,
    subtitle,
    selected,
    onClick,
    documentCount,
    updatedAt
}: KnowledgeBaseCardSelectableProps) => {
    const labelId = useId('knowledge-base-card-label');
    const descriptionId = useId('knowledge-base-card-description');
    const styles = useStyles();

    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            onClick?.();
        }
    };

    return (
        <Card
            appearance='filled-alternative'
            aria-selected={selected}
            className={`${selected ? styles.active : ''} ${styles.card}`}
            onClick={onClick}
            onKeyDown={handleKeyDown}
            aria-labelledby={labelId}
            aria-describedby={descriptionId}
            role={'button'}
        >
            <CardHeader
                image={
                    <img
                        src={
                            `https://ui-avatars.com/api?name=${title}&background=${stringToBrightColor(id)}`
                        }
                        alt={`${title} knowledge base logo`}
                        height={48}
                        width={48}
                    />
                }
                className={styles.header}
                header={<Text id={labelId} weight="semibold">{title}</Text>}
                description={
                    <Caption1 id={descriptionId} className={styles.caption}>{subtitle}</Caption1>
                }
            />
            <div className={styles.cardFooter}>
                <div className={styles.cardFooterItem}>
                    <span>Pages Indexed</span>
                    <span>{documentCount}</span>
                </div>

                <div className={styles.cardFooterItem}>
                    <span>Last Updated</span>
                    <span>{new Date(updatedAt).toLocaleDateString()}</span>
                </div>

            </div>
        </Card >
    )
}