import { AxiosResponse } from "axios";
import axios from "../providers/axios.provider";
import { KnowledgeBase } from "./models";

export type ReindexRequest = {
    knowledgeBaseId: string;
};

type ReindexResponse = {
    message: string;
};

export async function reindexKnowledgeBaseFetch(options: ReindexRequest): Promise<AxiosResponse<ReindexResponse>> {
    return await axios.post<ReindexResponse>(`/api/knowledge-base/${options.knowledgeBaseId}/reindex`);
}

export type CreateKnowledgeBaseRequest = {
    name: string;
    description?: string;
};

export async function createKnowledgeBaseRequest(options: CreateKnowledgeBaseRequest): Promise<AxiosResponse<KnowledgeBase>> {
    return await axios.post<KnowledgeBase>("/api/knowledge-base", {
        name: options.name,
        description: options.description
    });
}

export type GetKnowledgeBaseRequest = {
    knowledgeBaseId: string;
};

export async function getKnowledgeBaseRequest(options: GetKnowledgeBaseRequest): Promise<AxiosResponse<KnowledgeBase>> {
    return await axios.get<KnowledgeBase>(`/api/knowledge-base/${options.knowledgeBaseId}`);
}

export async function getKnowledgeBasesRequest(): Promise<AxiosResponse<KnowledgeBase[]>> {
    return await axios.get<KnowledgeBase[]>("/api/knowledge-base");
}

export type UpdateKnowledgeBaseRequest = {
    name: string;
    description?: string;
    chatBotTitle: string;
    chatBotSubtitle: string;
    systemMessage?: string | null;
    promptTemplate?: string | null;
    followUpQuestionsPrompt?: string | null;
};

export async function updateKnowledgeBaseRequest(id: string, options: UpdateKnowledgeBaseRequest): Promise<AxiosResponse<KnowledgeBase>> {
    const url = `/api/knowledge-base/${id}`;

    return await axios.patch(url, {
        name: options.name,
        description: options.description,
        followUpQuestionsPrompt: options.followUpQuestionsPrompt,
        chatBotTitle: options.chatBotTitle,
        chatBotSubtitle: options.chatBotSubtitle,
        promptTemplate: options.promptTemplate,
        systemMessage: options.systemMessage
    });
}

export type DeleteKnowledgeBaseRequest = {
    knowledgeBaseId: string;
};

export async function deleteKnowledgeBaseRequest(options: DeleteKnowledgeBaseRequest): Promise<AxiosResponse<void>> {
    return await axios.delete(`/api/knowledge-base/${options.knowledgeBaseId}`);
}
