import { useNavigate } from "react-router-dom";
import { useAppAbility } from "../../providers/ability.provider";
import { Actions, Subjects, updateAbilityForIdTokenClaims } from "../../rbac/define-ability";
import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";

type AbilityGuardProps = {
    children: React.ReactNode;
};

const AND = [] as { action: Actions, subject: Subjects }[];

const OR = [{
    action: 'write',
    subject: 'organization'
}, {
    action: 'write',
    subject: 'knowledge_bases'
}, {
    action: 'write',
    subject: 'question_examples'
}, {
    action: 'write',
    subject: 'ai_prompts'
}] as { action: Actions, subject: Subjects }[];

export const AdministrationGuard = ({ children }: AbilityGuardProps) => {
    const { instance } = useMsal()
    const activeAccount = instance.getActiveAccount();

    const ability = useAppAbility();
    const navigate = useNavigate();

    useEffect(() => {
        updateAbilityForIdTokenClaims(ability, activeAccount?.idTokenClaims);
        const hasAndPermission = AND.every(({ action, subject }) => ability.can(action, subject));
        const hasOrPermission = OR.some(({ action, subject }) => ability.can(action, subject));

        if (!(hasAndPermission && hasOrPermission)) {
            navigate("/");
        }
    }, [navigate, ability, AND, OR]);

    return <>{children}</>;
};
