import { AxiosResponse } from "axios";
import { User } from ".";
import axios from "../providers/axios.provider";

const BASE_URL = "/api/users";

export async function createCurrentUserRequest(): Promise<AxiosResponse<User>> {
    return await axios.post<User>(`${BASE_URL}/me`);
}

export async function getCurrentUserRequest(): Promise<AxiosResponse<User>> {
    return await axios.get<User>(`${BASE_URL}/me`);
}

export async function getUsersRequest(): Promise<AxiosResponse<User[]>> {
    return await axios.get<User[]>(BASE_URL);
}

export async function getUserRequest(id: string): Promise<AxiosResponse<User>> {
    return await axios.get<User>(`${BASE_URL}/${id}`);
}
