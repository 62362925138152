import { Title1, Body1Strong, makeStyles, tokens, shorthands, Body2 } from '@fluentui/react-components';
import CreateOrganizationForm from '../../features/create-organization';
import { useEffect, useState } from 'react';
import { AzureAdOrganization, requestAzureADOrganization } from '../../api/directories';
import { useMsal } from '@azure/msal-react';
import { getOrganizationRequest } from '../../api/organization';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAppAbility } from '../../providers/ability.provider';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        rowGap: '24px',
        width: '100%',
        minHeight: '100%',
        backgroundColor: tokens.colorNeutralBackground5,
        ...shorthands.padding('24px'),
    },
    content: {
        width: '100%',
        maxWidth: '600px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '24px',
        alignItems: 'center',
    },
    description: {
        textAlign: 'center',
    }
})

const CreateOrganizationPage = () => {
    const title = "Create Organization - Knowledge Interpreter";

    const styles = useStyles();

    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const navigate = useNavigate();

    const ability = useAppAbility();
    const canCreateOrganization = ability.can('write', 'organization')

    const [tenant, setTenant] = useState<AzureAdOrganization | undefined>();
    // const [organization, setOrganization] = useState<Organization>()
    const [isOrganizationLoading, setIsOrganizationLoading] = useState(false);
    const [organizationError, setOrganizationError] = useState('')

    useEffect(() => {
        const fetchOrganization = async () => {
            const organization = await requestAzureADOrganization()
                .then((response) => response.data?.value?.[0]);

            setTenant(organization)
        }

        fetchOrganization();
    }, [activeAccount?.tenantId])

    useEffect(() => {
        const getOrganization = async () => {
            try {
                setIsOrganizationLoading(true)

                const response = await getOrganizationRequest()

                if (response.data) {
                    navigate('/')
                }
            } catch (error: unknown) {
                if (error instanceof AxiosError) {
                    if (error.response) {
                        setOrganizationError('Error while requesting organization')
                    }
                }
            } finally {
                setIsOrganizationLoading(false)
            }
        }

        if (tenant?.id) {
            getOrganization()
        }
    }, [tenant?.id])

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <div className={styles.root}>
                <div className={styles.content}>
                    <Title1 as="h1">
                        Create Organization
                    </Title1>
                    <Body1Strong className={styles.description}>
                        Welcome Aboard! Let's Get Started by Naming Your Organization
                    </Body1Strong>

                    {canCreateOrganization ? (
                        <CreateOrganizationForm isLoading={isOrganizationLoading} />
                    ) : (
                        <Body2>
                            You don't have permission to create an organization. Please contact your administrator.
                        </Body2>
                    )}
                </div>
            </div>
        </>
    )
}

export default CreateOrganizationPage;