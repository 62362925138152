import { AxiosResponse } from "axios";
import { Organization } from ".";
import axios from "../providers/axios.provider";
import { uploadFile } from "./api";

const BASE_URL = "/api/organization";

type CreateOrganizationRequest = {
    name?: string;
    description?: string;
    homeUrl?: string;
    consentScreenEnabled?: boolean;
    consentScreenText?: string;
    consentScreenTitle?: string;
};

export async function createOrganizationRequest(data: CreateOrganizationRequest): Promise<AxiosResponse<Organization>> {
    return await axios.post<Organization>(BASE_URL, data);
}

export async function getOrganizationRequest(): Promise<AxiosResponse<Organization>> {
    return await axios.get<Organization>(BASE_URL);
}

type UpdateOrganizationRequest = {
    homeUrl: string;
    name: string;
    description: string;
    consentScreenEnabled: boolean;
    consentScreenText: string;
    consentScreenTitle: string;
};

export async function updateOrganizationRequest(options: UpdateOrganizationRequest): Promise<AxiosResponse<Organization>> {
    return await axios.put<Organization>(BASE_URL, options);
}

type UploadLogoRequest = {
    file: File;
    onProgress?: (event: ProgressEvent) => void;
    onError?: (event: ProgressEvent) => void;
    onLoad?: (event: ProgressEvent) => void;
    token?: string;
};

type UploadLogoResponse = {
    message: string;
};

export async function uploadOrganizationLogoRequest(options: UploadLogoRequest): Promise<UploadLogoResponse> {
    const url = `/api/organization/logo`;
    const method = "PUT";

    return uploadFile<UploadLogoResponse>({
        url,
        file: options.file,
        method,
        onProgress: options.onProgress,
        onError: options.onError,
        onLoad: options.onLoad,
        token: options.token
    });
}

export async function deleteOrganizationLogoRequest(): Promise<AxiosResponse<void>> {
    return await axios.delete(`${BASE_URL}/logo`);
}

export async function deleteOrganizationRequest(): Promise<AxiosResponse<void>> {
    return await axios.delete(BASE_URL);
}
