import {
    Dialog,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Button,
    DialogTrigger,
} from '@fluentui/react-components';
import { useEffect, useId, useRef, useState } from 'react';
import styles from "./guidelines-and-consent.module.scss";
import { useOrganization } from '../../providers/organization.provider';
import DOMPurify from "dompurify";

export const GuidelinesAndConsent = () => {
    const dialogTitleId = useId();
    const dialogContentId = useId();

    const agreeButtonRef = useRef<HTMLButtonElement>(null);
    const dialogRef = useRef<HTMLDivElement>(null);
    const organization = useOrganization();

    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (!organization.isOrganizationLoading && organization.organization?.consentScreenEnabled) {
            setTimeout(() => setIsModalOpen(true), 300);
        }
    }, [
        organization.isOrganizationLoading,
        organization.organization?.consentScreenEnabled,
    ]);

    // Sanitize the content
    const sanitizedContent = DOMPurify.sanitize(organization.organization?.consentScreenText || '');

    return (
        <Dialog open={isModalOpen} modalType="alert" >
            <DialogSurface>
                <DialogBody className={styles.consentDialogBody}>
                    <DialogTitle id={dialogTitleId} tabIndex={0}>
                        {organization.organization?.consentScreenTitle}
                    </DialogTitle>
                    <DialogContent id={dialogContentId}>
                        <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
                    </DialogContent>
                    <DialogActions autoFocus={false} className={styles.consentDialogActions}>
                        <DialogTrigger disableButtonEnhancement>
                            <Button ref={agreeButtonRef} appearance="primary" onClick={
                                () => {
                                    setIsModalOpen(false);
                                }
                            }>
                                I Agree
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}